<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <li class="list-item list-test pl-1" data-tooltip="Codepen">
      <b-row>
        <a href="https://www.rugscan.finance/" target="_blank">
          <div class="d-flex align-items-center pl-1">
            <b-avatar rounded size="35" variant="light-company" class="sidebar-img">
              <b-img-lazy
                center
                fluid
                src="@/assets/images/nav-icons/rugscan.svg"
                alt="avatar img"
                class="image-size"
            /></b-avatar>
            <span class="pointer">Rugscanner</span>
          </div>
        </a>
      </b-row>
    </li>
    <li class="list-item list-test pl-1" data-tooltip="Codepen">
      <b-row>
        <a href="https://newcryptohunts.com/" target="_blank">
          <div class="d-flex align-items-center pl-1">
            <b-avatar rounded size="35" variant="light-company" class="sidebar-img">
              <b-img-lazy
                center
                fluid
                src="@/assets/images/nav-icons/blog.svg"
                alt="avatar img"
                class="image-size"
            /></b-avatar>
            <span class="pointer">BLOG</span>
          </div>
        </a>
      </b-row>
    </li>
    <li class="list-item list-test pl-1" data-tooltip="Codepen">
      <b-row>
        <a href="https://migrator.rugfreecoins.com/" target="_blank">
          <div class="d-flex align-items-center pl-1">
            <b-avatar rounded size="30" variant="light-company" class="sidebar-img">
              <b-img-lazy
                center
                fluid
                src="@/assets/images/nav-icons/migrator.svg"
                alt="avatar img"
                class="image-size"
            /></b-avatar>
            <span class="pointer">Migrator</span>
          </div>
        </a>
      </b-row>
    </li>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import { BAvatar, BImgLazy, BRow } from "bootstrap-vue";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BAvatar,
    BImgLazy,
    BRow,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
};
</script>
