// import Vue from 'vue'
// import testFile from './testFile.vue';

// new Vue({
//   render: h => h(testFile),
// }).$mount('#app')

export default [{
    title: 'Home',
    route: 'home',
    // icon: '../assets/images/nav-icon/home.svg',
    icon: require('@/assets/images/nav-icons/home.svg'),
    activeicon: require('@/assets/images/nav-icons/whome.svg'),
},
{
    title: 'Add Coin',
    route: 'add-coin',
    icon: require('@/assets/images/nav-icons/add-coin.svg'),
    activeicon: require('@/assets/images/nav-icons/wadd-coin.svg'),
    // icon: 'https://coinhunters.cc/img/tokens.png',
},
{
    title: 'Promote',
    route: 'promote',
    icon: require('@/assets/images/nav-icons/promote.svg'),
    activeicon: require('@/assets/images/nav-icons/wpromote.svg'),
    // icon: 'https://coinhunters.cc/img/promote.gif',
},
{
    title: 'Audit',
    route: 'audit-coin',
    icon: require('@/assets/images/nav-icons/audit.svg'),
    activeicon: require('@/assets/images/nav-icons/waudit.svg'),
    // icon: 'https://pbs.twimg.com/profile_images/950432257222656000/SzfXLoxL_400x400.jpg',
},
{
    title: 'Ape',
    route: 'ape-coins',
    icon: require('@/assets/images/nav-icons/audit.svg'),
    activeicon: require('@/assets/images/nav-icons/waudit.svg'),
    // icon: 'https://pbs.twimg.com/profile_images/950432257222656000/SzfXLoxL_400x400.jpg',
},
{
    title: 'Services',
    route: 'services-we-offer',
    icon: require('@/assets/images/nav-icons/services.svg'),
    activeicon: require('@/assets/images/nav-icons/wservices.svg'),
    // icon: 'https://www.brainvire.com/wp/wp-content/uploads/2020/07/banner-image-Enterprise.png',
},
{
    title: 'Presales & Lunches',
    route: 'presale',
    // icon: 'https://coinhunters.cc/img/baby.1.png',
    icon: require('@/assets/images/nav-icons/presale.svg'),
    activeicon: require('@/assets/images/nav-icons/wpresale.svg'),
},
{
    title: 'Calender',
    route: 'calender',
    // icon: 'https://icon-library.com/images/menu-icon-material-design/menu-icon-material-design-12.jpg',
    icon: require('@/assets/images/nav-icons/calendar.svg'),
    activeicon: require('@/assets/images/nav-icons/wcalendar.svg'),
},
    // {
    //     title: 'Contest',
    //     route: 'contest',
    // icon: require('@/assets/images/nav-icons/contest.svg'),
    //     icon: 'https://coinmooner.com/icon/celebration.png',
    // activeicon: require('@/assets/images/nav-icons/wcontest.svg'),
    // },
]