<template>
  <div>
    <link rel="stylesheet" type="text/css" href="style.css" />
    <link
      href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      rel="stylesheet"
      integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
      crossorigin="anonymous"
    />
    <link
      rel="stylesheet"
      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
      crossorigin="anonymous"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <div class="SocialMedia">
      <ul>
        <b-row>
          <b-col
            cols="12"
            xl="12"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <li class="line-heigts">
                <a target="_blank" href="https://www.facebook.com/rugfreecoins"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li class="line-heigts">
                <a target="_blank" href="https://twitter.com/rugfreecoins"><i class="fab fa-twitter"></i></a>
              </li>
              <li class="line-heigts">
                <a target="_blank" href="https://t.me/rugfreecoin"><i class="fab fa-telegram"></i></a>
              </li>
            </div>
          </b-col>
        </b-row>
      </ul>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.SocialMedia {
  position: absolute;
  text-align: center;
}
.SocialMedia ul {
  list-style-type: none;
  margin-top: 20px;
}
.SocialMedia ul li {
  display: inline-block;
  margin: 5px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 20px;
  font-size: 15px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4),
    inset 0px 0px 12px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .SocialMedia ul li {
    line-height: 0px !important;
    font-size: 12px;
  }
  .SocialMedia ul li a i{
    padding-bottom: 100px !important;
  }
  .SocialMedia ul {
    margin-top: -5px !important;
    margin-left: -20px !important;
  }
  .header-navbar .navbar-container ul.navbar-nav li {
    line-height: 2 !important;
}
}
.SocialMedia ul li:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5),
    inset 0px 0px 12px rgba(0, 0, 0, 0.5);
}
.SocialMedia ul li a {
  transition: 0.5s;
}
.SocialMedia ul li:first-of-type a {
  color: #3b5999;
}
.SocialMedia ul li:nth-of-type(2) a {
  color: #55acee;
}
.SocialMedia ul li:nth-of-type(3) a {
  color: #25d366;
}
.SocialMedia ul li:nth-of-type(4) a {
  color: #0077b5;
}
.SocialMedia ul li:last-of-type a {
  color: #0077b5;
}
.SocialMedia ul li:hover a {
  color: #fff;
}

.SocialMedia ul li:first-of-type:hover {
  background-color: #3b5999;
  color: #fff;
}
.SocialMedia ul li:nth-of-type(2):hover {
  background-color: #55acee;
}
.SocialMedia ul li:nth-of-type(3):hover {
  background-color: #25d366;
}
.SocialMedia ul li:nth-of-type(4):hover {
  background-color: #0077b5;
}
.SocialMedia ul li:last-of-type:hover {
  background-color: #0077b5;
}
</style>