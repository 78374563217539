<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <b-row>
        <b-col cols="12" xl="12" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <li class="nav-item">
              <b-link class="nav-link" @click="toggleVerticalMenuActive">
                <feather-icon icon="MenuIcon" size="21" />
              </b-link>
            </li>
            <!-- <dark-Toggler /> -->
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
            </b-link>
            <v-google-translate
              v-if="is_mobilesize"
              :languages="countries"
              class="translator-padding"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xl="12" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <span class="pointer span_class font-weight-bold" @click="linked('add-coin')"
              ><small class="font-weight-bold"
                ><b>{{ $t("navbar.addCoin") }}</b></small
              ></span
            >
            <span class="pointer pl-1 span_class" @click="linked('promote')"
              ><small class="font-weight-bold"
                ><b>{{ $t("navbar.promote") }}</b>
              </small></span
            >
            <!-- <span class="pointer pl-1 span_class" @click="linked('\contest')"
              ><small class="font-weight-bold"
                ><b>{{ $t("CONTEST") }}</b>
              </small></span
            > -->
            <span class="pointer pl-1 span_class" @click="linked('audit-coin')"
              ><small class="font-weight-bold"
                ><b>{{ $t("navbar.audit") }}</b>
              </small></span
            >
            <span class="pointer pl-1 span_class" @click="linked('services-we-offer')"
              ><small class="font-weight-bold"><b>SERVICES</b></small></span
            >
            <socials3 v-if="is_mobilesize" />
          </div>
        </b-col>
      </b-row>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <countDown /> -->
      <!-- <logo /> -->

      <!-- <div v-if="!is_mobilesize" class="ml-1">
        <span
          class="pointer pl-1 span_class font-weight-bold"
          @click="linked('add-coin')"
          ><small class="font-weight-bold"
            ><b>{{ $t("navbar.addCoin") }}</b></small
          ></span
        >
        <span class="pointer pl-1 span_class" @click="linked('promote')"
          ><small class="font-weight-bold"
            ><b>{{ $t("navbar.promote") }}</b>
          </small></span
        >
        <span class="pointer pl-1 span_class" @click="linked('audit-coin')"
          ><small class="font-weight-bold"
            ><b>{{ $t("navbar.audit") }}</b>
          </small></span
        >
        <span
          class="pointer pl-1 span_class"
          @click="linked('services-we-offer')"
          ><small class="font-weight-bold"><b>SERVICES</b></small></span
        >

        <span class="pointer pl-1 span_class" @click="linked('presale')"
          ><small class="font-weight-bold"><b>PRESALE</b> </small></span
        >
        <span class="pointer pl-1 span_class"
          ><small class="font-weight-bold">
            <b-link
              class="b-link-class"
              target="_blank"
              href="https://www.rugscan.finance/"
              ><b>RUGSCANNER</b></b-link
            ></small
          ></span
        >
        <span class="pointer pl-1 span_class pr-1"
          ><small class="font-weight-bold">
            <b-link
              class="b-link-class"
              target="_blank"
              href="https://newcryptohunts.com/"
              ><b>BLOG</b></b-link
            ></small
          ></span
        >
        <feather-icon
          @click="linked('calender')"
          icon="CalendarIcon"
          size="18"
          class="pointer"
        />
      </div> -->
    </div>

    <div class="pr-2" v-if="!is_mobilesize && !check_is_login">
      <b-button
        size="sm"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @mouseover="mouseoverLogin()"
        @mouseleave="mouseoverLogin()"
        variant="success"
        @click="linked('login')"
        >LOGIN</b-button
      >
    </div>
    <div class="pr-5" v-if="!is_mobilesize && !check_is_login">
      <b-button
        size="sm"
        @mouseover="mouseover()"
        @mouseleave="mouseover()"
        :variant="mouseover_check ? 'success' : 'outline-success'"
        @click="linked('register')"
        >SIGNUP</b-button
      >
    </div>
    <!-- <locale /> -->
    <v-google-translate v-if="!is_mobilesize" :languages="countries" />

    <socials3
      v-if="!is_mobilesize"
      :class="check_is_login ? 'pb-2 pr-5' : 'pb-2 loged-social-class'"
    />
    <b-navbar-nav
      class="nav align-items-center ml-auto profile-dropdown"
      v-show="check_is_login"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ profileName }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :text="helper.getNameText(user_name)"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="linked('profile')"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
// import Locale from '@core/layouts/components/app-navbar/components/Locale.vue';
// import { Translator } from "vue-google-translate";
import socials3 from "./../../views/social3.vue";
import { mixinList } from "@/mixins/mixinList";
import helper from "@/utils/helper";
import { $themeConfig } from "@themeConfig";
// import countDown from "./../../views/CountDown.vue";
// import logo from "./../../views/Logo.vue";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BImg,
  BRow,
  BCol,
} from "bootstrap-vue";
// import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Ripple from "vue-ripple-directive";
export default {
  mixins: [mixinList],
  data() {
    return {
      helper: helper,
      mouseover_check: false,
      mouseover_check_login: false,
      show_translater: "",
      countries_mobile: [
        {
          code: "en",
          name: "EN",
          cname: "英语",
          ename: "",
        },
        {
          code: "ru",
          name: "RU",
          cname: "俄语",
          ename: "",
        },
        {
          code: "zh-CN",
          name: "zh-CN",
          cname: "中文 (简体)",
          ename: "",
        },
        {
          code: "ja",
          name: "JPN",
          cname: "日语",
          ename: "",
        },
        {
          code: "ko",
          name: "KO",
          cname: "韩语",
          ename: "",
        },
        {
          code: "es",
          name: "ES",
          cname: "西班牙语",
          ename: "",
        },
        {
          code: "ar",
          name: "AR",
          cname: "阿拉伯语",
          ename: "",
        },
        {
          code: "pt",
          name: "PT",
          cname: "葡萄牙语",
          ename: "",
        },
        {
          code: "fr",
          name: "FR",
          cname: "法语",
          ename: "",
        },
      ],
      countries: [
        {
          code: "en",
          name: "English",
          cname: "英语",
          ename: "English",
        },
        {
          code: "ru",
          name: "Русский",
          cname: "俄语",
          ename: "Russian",
        },
        {
          code: "zh-CN",
          name: "中国人",
          cname: "中文 (简体)",
          ename: "Chinese",
        },
        {
          code: "ja",
          name: "にほんご",
          cname: "日语",
          ename: "Japanese",
        },
        {
          code: "ko",
          name: "한국어",
          cname: "韩语",
          ename: "Korean",
        },
        {
          code: "es",
          name: "Español",
          cname: "西班牙语",
          ename: "Spanish",
        },
        {
          code: "ar",
          name: "العربية",
          cname: "阿拉伯语",
          ename: "Arabic",
        },
        {
          code: "pt",
          name: "Português",
          cname: "葡萄牙语",
          ename: "Portuguese",
        },
        {
          code: "fr",
          name: "Français",
          cname: "法语",
          ename: "French",
        },
      ],
    };
  },
  components: {
    BRow,
    BImg,
    BButton,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    // DarkToggler,
    socials3,
    BCol,
    // countDown,
    // logo,
  },
  setup() {
    // App Name
    const { appLogoImage, appName } = $themeConfig.app;
    return {
      appLogoImage,
      appName,
    };
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    profileName() {
      let userData = JSON.parse(localStorage.getItem("profile"));
      if (userData) {
        return userData.name;
      }
      return "";
    },
    user_name() {
      let userData = JSON.parse(localStorage.getItem("profile"));
      if (userData) {
        let name = userData.name || [];
        return name;
      }
      return null;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      location.reload();
    },
    linked: function (e) {
      this.$router.push({ path: "/" + e });
    },
    mouseover() {
      this.mouseover_check = !this.mouseover_check;
    },
    mouseoverLogin() {
      this.mouseover_check_login = !this.mouseover_check_login;
    },
  },
  mounted() {
    this.checkIsLogin();
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.span_class {
  /* font-family: "Montserrat", Helvetica, Arial, serif; */
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.45;
  /* color: #5e5873; */
}
img {
  border-style: none;
  width: 29px;
  margin-top: -45px;
  margin-left: 100px;
}
.height-change {
  height: 200px;
}
.b-link-class {
  color: #b4b7bd;
}
@media (max-width: 1024px) {
  /* .header-navbar .navbar-nav {
    margin-bottom: 2rem;
  } */
  .profile-dropdown {
    margin-top: -30px !important;
  }
}
.loged-social-class {
  margin-right: 150px !important;
}
.translator-padding {
  padding: 0px !important;
  margin-top: -15px !important;
}
/* [dir="ltr"] .header-navbar .navbar-container {
  margin-left: 0;
  background: #0c0d20;
} */
</style>
